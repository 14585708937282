<template>
    <div v-if="dialog">
        <v-dialog :value="dialog"
                  persistent
                  transition="dialog-bottom-transition">

            <v-card>
                <v-toolbar>
                    <v-btn icon @click="$emit('onCloseDialog')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title> {{ !setting._id ? $t('title.create_yukiSetting') :$t('title.update_yukiSetting')  }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn v-if="loading"
                               disabled
                               text
                               @click="validate">
                            {{ !setting._id ? $t('button.create') : $t('button.update') }}
                        </v-btn>
                        <v-btn v-else
                               text
                               @click="validate">
                            {{ !setting._id ? $t('button.create') : $t('button.update') }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <Loading :visible="loading" />
                <v-card-text>
                    <v-container>
                        <!-- <v-form ref="form"
                                v-model="valid"
                                lazy-validation>
                            
                            <v-row class="mt-5">
                                <v-text-field v-model="setting.max_lose"
                                              :label="$t('input.max_loose')"
                                              :rules="rules.max_lose"
                                              type="number" />
                            </v-row>
                            <v-row class="mt-5">
                                <v-text-field v-model="setting.max_win"
                                              :label="$t('input.max_win')"
                                              :rules="rules.max_win"
                                              type="number" />
                            </v-row>
                           
                        </v-form> -->
                        <v-form ref="form"
                                v-model="valid"
                                lazy-validation>
                            
                            <v-row class="mt-5">
                                <v-text-field v-model="setting.percentage"
                                              :label="$t('input.percentage')"
                                              :rules="rules.percentage"
                                              type="number" />
                            </v-row>
                            <!-- <v-row class="mt-5">
                                <p>{{$t('input.win_lose_type')}} :</p>
                                <v-radio-group v-model="setting.win_lose_type">
                                    <v-radio value="1">
                                        <template v-slot:label>
                                        <div>{{$t('input.one_day')}} </div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="2">
                                        <template v-slot:label>
                                        <div>{{$t('input.one_month')}}</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-row> -->
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            dialog: {
                type: Boolean,
                default: false
            },
            darkmode: {
                type: Boolean
            },
            loading: {
                type: Boolean
            },
            rules: {
                type: Object,
                default: function () {
                    return {
                        percentage: [
                            v => !!v || 'Percentage is required',
                        ],
                    }
                }
            },
            isValidate: {
                type: Boolean,
                default: true
            },
            setting: { 
                type: Object, 
                default: () =>({
                        _id: null,
                        name: null,
                        is_enable: false,
                        is_start: false,
                        user_id: null,
                        max_lose: 0,
                        max_win: 0,
                        percentage: 0,
                        updatedAt: null
                    }
                )
            },
        },
        computed: {
            valid: {
                // getter
                get: function () {
                    return this.isValidate
                },
                // setter
                set: function (newValue) {
                    this.$emit('update:isValidate', newValue)
                }
            }
        },
        watch: {
            
        },
        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.$emit('submit')
                }
            },
        }
    }

</script>
